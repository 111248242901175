.review-side-form {
  background-color: white;
  height: 460px;
}

@include respond-up('large') {
  .review-side-form {

  }
}

@include respond-down('medium') {
  .review-side-form {

  }
}
