.capper-filterable-list {
  position: relative;

  &._replacing {
    .capper-filterable-list {
      &__list {
        &._absolute {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }
      }

      &__empty {
        opacity: 0;
      }
    }
  }

  &__list {
    height: var(--list-height);
    position: relative;
    z-index: 30;
    background-color: var(--primary-8);
  }

  &__empty {
    position: relative;
    z-index: 20;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-weight: 700;
    font-size: 20px;
    background-color: var(--primary-8);
    text-align: center;
    border-top: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
  }

  &__item {
    transition: {
      property: transform, opacity;
      timing-function: var(--default-transition-function);
    }
  }
}

@include respond-up('large') {
  .capper-filterable-list {
    &__empty {
      height: var(--list-height, 660px);
    }

    &__capper {
      height: calc(var(--list-height, 660px) / 10);
    }

    &._replacing {
      .capper-filterable-list {
        &__list {
          &._enter {
            .capper-filterable-list__item {
              transform: translateY(10px);
              opacity: 0;
            }
          }
          &._out {
            .capper-filterable-list__item {
              opacity: 0;
            }
          }
        }
      }
    }

    &__item {
      transition-duration: .3s;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: calc(#{$i} * .04s);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .capper-filterable-list {
    &__empty {
      height: var(--list-height);
    }

    &__capper {
      height: 133px;
    }

    &._replacing {
      .capper-filterable-list {
        &__list {
          &._enter {
            background-color: var(--t);
            .capper-filterable-list__item {
              transform: translateX(100%);
            }
          }
        }
      }
    }

    &__item {
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-duration: calc(.6s - #{$i} * .01s);
          transition-delay: calc(#{$i} * .04s);
        }
      }
    }
  }
}