.review-filter {
  &__form {

  }

  &__fields {
    display: flex;
  }

  &__field {

    &._sort {
      position: relative;
    }

    .radio-list {
      display: flex;
      background-color: white;
      border-radius: 6px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

      label {
        height: 100%;
        color: var(--primary-color);
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }

      input {
        display: none;

        &:checked {
          + label {
            background-color: var(--primary-10);
          }
        }
      }

      li {
        display: flex;
        align-items: center;

        label {
          padding: 0 12px;
          margin: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        &:nth-child(n + 2) {
          border-left: 1px solid var(--gray-light);
          label {
            padding-top: 7px;
            padding-bottom: 3px;
          }

          .radio-list__title {
            display: none;
          }
        }

        &:nth-child(2) {
          svg path {
            [stroke] {
              stroke: var(--primary-color);
            }
            [fill] {
              fill: var(--primary-color);
            }
          }
        }

        &:nth-child(3) {
          svg path {
            &[stroke] {
              stroke: var(--color-red);
            }
            &[fill] {
              fill: var(--color-red);
            }
          }
        }
      }
    }

    select {
      background-color: var(--t);
      border: unset;
      color: var(--primary-color);
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .review-filter {
    &__fields {
      justify-content: space-between;
    }

    &__arrow {
      right: 0;
    }

    &__field {
      select {
        text-align: right;
        padding-right: 15px;
      }
    }
  }
}

@include respond-down('medium') {
  .review-filter {
    &__fields {
      flex-direction: column;
      align-items: flex-start;
    }

    &__arrow {
      left: 0;
    }

    &__field {
      select {
        text-align: left;
        padding-left: 15px;
      }
    }
  }
}
