.capper-pagination {
  background: var(--primary-gradient);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
  height: 46px;


  &__list {
    display: flex;
    justify-content: center;
  }

  &__item {
    background-color: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    margin: 0 5px;
    border-radius: 50px;
    border: 1px solid var(--t);

    &_first, &_last {
    }

    svg path {
      stroke: white;
    }

    &_prev {
      svg {
        transform: rotate(90deg);
      }
    }

    &_next {
      transform: rotate(-90deg);
    }

    &._active {
      border: 1px solid var(--primary-color);
      background-color: white;
      color: var(--primary-color);
      .capper-pagination__link {
        color: var(--primary-color);
      }
    }

  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: white;
  }

  &__dots {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
  }
}

@include respond-up('large') {
  .capper-pagination {

    &__item {
      margin: 0 10px;
      transition: {
        property: border-color;
        duration: var(--default-transition-timing);
      };

      &:hover {
        border-color: white;
      }
    }
  }
}

@include respond-up('medium') {
  .capper-pagination {

  }
}

@include respond('medium') {
  .capper-pagination {

  }
}

@include respond-down('medium') {
  .capper-pagination {
    height: 46px;

    &__item {
      margin: 0 5px;
    }
  }
}

@include respond-down('small') {
  .capper-pagination {

  }
}