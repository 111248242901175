.index-page {
  &__title {
    font-style: italic;
  }
}

@include respond-up('large') {
  .index-page {
    padding: 50px 0 180px;

    &__cappers {
      margin: 22px 0 92px;
    }
  }
}

@include respond-up('medium') {
  .index-page {

  }
}

@include respond('medium') {
  .index-page {

  }
}

@include respond-down('medium') {
  .index-page {
    &__cappers {
      margin: 22px 0 46px;
    }
  }
}

@include respond-down('small') {
  .index-page {

  }
}