.capper-filter {
  &__form {
    background-color: white;
  }

  &__fields {
    display: flex;
  }

  &__field {
    .checkbox-list {
      li {
        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: var(--primary-color);
        }
      }
    }

    &_hidden {
      display: none;
    }
  }
}

@include respond-up('large') {
  .capper-filter {

    &__form {
      border-radius: 6px 6px 0 0;
    }

    &__opener {
      display: none;
    }

    &__fields {
      flex-wrap: wrap;
      padding: 21px 11px;
    }

    &__field {
      &_sport {
        margin-bottom: 21px;
        flex: 0 0 100%;
      }

      &_event_type {
        margin-right: 40px;
      }

      &_sort {
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .capper-filter {

  }
}

@include respond('medium') {
  .capper-filter {

  }
}

@include respond-down('medium') {
  .capper-filter {
    &__form {
      height: 0;
      overflow: hidden;
      transition: {
        property: height;
        duration: var(--default-transition-timing);
      };
    }

    &__inner {
      &._opened {
        .capper-filter {
          &__opener {
            &::before {
              width: 100%;
            }
          }
          &__opener-arrow {
            transform: rotate(-180deg);
          }
        }
      }
    }

    &__fields {
      flex-direction: column;
      padding: 11px;
    }

    &__opener {
      display: flex;
      justify-content: space-between;
      background: var(--primary-gradient);
      padding: 8px 10px;
      border-radius: 6px 6px 0 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1px;
        background-color: var(--primary-color);
        transition: {
          property: width;
          duration: var(--default-transition-timing);
        };
      }
    }

    &__opener-text {
      font-weight: 700;
      font-size: 15px;
      color: white;
      display: flex;
      align-items: center;
    }

    &__opener-arrow {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
      transition: {
        property: transform;
        duration: var(--default-transition-timing);
      };
      svg {
        display: flex;
        align-items: center;
        justify-content: center;
        path {
          stroke: white;
        }
      }
    }

    &__field {
      &_sort {
        position: relative;
        padding-top: 10px;

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 17/14*1em;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: var(--primary-color);
          margin-bottom: 9px;
        }

        select {
          height: 37px;
          background-color: var(--t);
          border: 1px solid var(--primary-color);
          border-radius: 6px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.05em;
          color: var(--primary-color);
          background: {
            image: url('/static/images/svg/arrow-down.svg');
            position: right 12px center;
            repeat: no-repeat;
          };
        }
      }
    }
  }
}

@include respond-down('small') {
  .capper-filter {

  }
}