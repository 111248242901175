.capper-item {
  display: grid;

  &._odd {
    background-color: white;
  }

  &._even {
    background-color: var(--primary-10);
  }

  &__capper {
    display: flex;
    align-items: center;
  }

  &__number-block {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 6px;
    width: 72px;
    height: 38px;
    padding-left: 3px;

    &_green {
      background: var(--primary-gradient);
    }

    &_red {
      background: var(--red-gradient);
    }

    &_gray {
      background: var(--color-gray-text);
    }
  }

  &__image-wrap {
    position: absolute;
    background-color: black;
    border: 3px solid white;
    border-radius: 100px;
    overflow: hidden;
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    right: calc(50% - 25px);
  }

  &__name {
    font-weight: 700;
    padding-left: 13px;
  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    object-position: center;
    object-fit: cover;
  }

  &__rating, &__reviews, &__sport {
    display: flex;
    align-items: center;
  }

  &__reviews-block {
    display: flex;
    flex: 0 0 calc(100%/3);
    --current-color: var(--primary-color);

    &_green {
      --current-color: var(--primary-color);
    }

    &_gray {
      --current-color: var(--color-gray-text);
    }

    &_red {
      --current-color: var(--color-red);
    }
  }

  &__reviews-number {
    font-weight: 700;
    font-size: 20px;
    line-height: 24/20*1em;
    letter-spacing: 0.05em;
    color: var(--current-color);
  }

  &__reviews-icon {
    display: flex;
    align-items: center;
    margin-right: 7px;
    width: 20px;
    flex: 0 0 20px;
    height: 20px;

    svg path {
      stroke: var(--current-color);
    }
  }

  &__sport-block {
    display: flex;
    align-items: center;
    background-color: var(--primary-10);
    border-radius: 6px;
    padding: 4px 6px;
  }

  &__sport-icon {
    flex: 0 0 17px;
    width: 17px;
    height: 17px;
    margin-right: 6px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__sport-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17/14*1em;
    letter-spacing: 0.05em;
    color: var(--primary-color);
  }

  &__reviews-icon {
    padding-top: 5px;
  }

  &__button-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: var(--color-yellow);
    color: var(--color-bg-light);
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: var(--h-font);
    padding: 8px 0 9px;
    width: 95px;
    font-size: 12px;
    line-height: 15/12*1em;
  }

  &__diff {
    grid-column: 8/10;
    font-weight: 700;
    font-size: 20px;
    line-height: 24/20*1em;
    letter-spacing: 0.05em;
    align-items: center;
    display: flex;

    &_green {
      color: var(--primary-color);
    }

    &_red {
      color: var(--color-red);
    }

    &_gray {
      color: var(--color-gray-text);
    }
  }
}

@include respond-up('large') {
  .capper-item {
    height: calc(var(--list-height, 665px) / 5);

    padding: 0;
    grid-gap: 0 var(--grid-gap);
    grid-template-columns:
            repeat(9, 1fr)
            repeat(2, 1.5fr);

    > *:first-child {
      padding-left: 20px;
    }

    > *:last-child {
      padding-right: 20px;
    }

    &__button-wrap {
      grid-column: 8/9;
    }

    &__name {
      font-size: 14px;
      line-height: 17/17*1em;
    }

    &__capper {
      grid-column: 1/4;
    }

    &__rating {
      grid-column: 4/5;
    }

    &__reviews {
      grid-column: 4/8;
      padding: 0 25px;
    }

    &__diff {
      grid-column: 8/10;
      padding-left: 25px;
    }

    &__sport {
      grid-column: 10/11;
    }

    &__button-wrap {
      grid-column: 11/12;
    }
  }
}

@include respond-up('medium') {
  .capper-item {

  }
}

@include respond('medium') {
  .capper-item {

  }
}

@include respond-down('medium') {
  .capper-item {
    grid-gap: 12px 0;
    padding: 10px;
    grid-template-columns: repeat(3, 1fr);

    &__capper {
      grid-column: 1/4;
      grid-row: 1/2;
    }

    &__reviews {
      grid-column: 1/4;
      grid-row: 2/3;
    }

    &__sport {
      grid-column: 1/2;
      grid-row: 3/4;
    }

    &__diff {
      grid-column: 2/3;
      grid-row: 3/4;
      justify-content: center;
    }

    &__button-wrap {
      grid-column: 3/4;
      grid-row: 3/4;
    }

    &__name {
      font-size: 18px;
      line-height: 22/18*1em;
    }
  }
}