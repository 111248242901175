.header {
  padding-bottom: var(--header-height);

  &__outer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    filter: drop-shadow(0px 1px 30px rgba(0, 0, 0, 0.1));
  }

  &__inner {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
  }

  &__logo-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__logo-icon {
    display: flex;
    align-items: flex-end;
  }

  &__logo-text {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: 10px;
    line-height: 12/10 * 1em;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: var(--dark-shades);
  }
}

@include respond-up('large') {
  .header {
    &__logo-text {
      text-align: right;
      justify-content: flex-end;
    }
  }
}

@include respond-up('medium') {
  .header {

  }
}

@include respond('medium') {
  .header {

  }
}

@include respond-down('medium') {
  .header {
    &__logo-text {
      text-align: left;
      justify-content: flex-start;
    }


  }
}

@include respond-down('small') {
  .header {

  }
}