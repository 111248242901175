.capper-columns {

}

@include respond-up('large') {
  .capper-columns {
    display: grid;
    grid-gap: var(--grid-gap);
    background: var(--primary-gradient);
    grid-template-columns:
            repeat(9, 1fr)
            repeat(2, 1.5fr);

    &__column {
      display: flex;
      align-items: center;

      &_name {
        grid-column: 1/4;
        padding-left: 10px;
      }

      &_reviews {
        grid-column: 4/8;
        justify-content: center;
      }

      &_diff {
        grid-column: 8/10;
      }
      &_sport {
        grid-column: 10/12;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      padding: 15px 0;

      &._active {
        svg path{
          stroke: var(--color-bg);
        }
      }

      &._up {
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    &__name {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.05em;
      color: var(--color-bg);
    }

    &__arrow {
      margin-left: 3px;
      display: flex;
      align-items: center;

      svg {
        display: flex;
        align-items: center;
        transition: {
          property: transform;
          duration: var(--default-transition-timing);
        };

        path {
          stroke: rgba(12, 27, 34, 0.2);
          transition: {
            property: stroke;
            duration: var(--default-transition-timing);
          };
        }
      }
    }
  }
}

@include respond-up('medium') {
  .capper-columns {

  }
}

@include respond('medium') {
  .capper-columns {

  }
}

@include respond-down('medium') {
  .capper-columns {
    display: none;
  }
}

@include respond-down('small') {
  .capper-columns {

  }
}