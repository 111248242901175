.capper-review-form {
  &__form {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

    &.success {
      .capper-review-form {
        &__success {
          opacity: 1;
          pointer-events: all;
        }

        &__success-icon {
          background-color: var(--primary-color);

          svg {
            opacity: 1;
          }
        }

        &__success-circle {
          opacity: 1;
          transform: scale(1);
        }

        &__success-title, &__success-subtitle {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__main {
    background-color: white;
    position: relative;
  }

  &__title {
    color: white;
    text-align: center;
    background-color: var(--primary-color);
    font-weight: 700;
    font-size: 23px;
    line-height: 28/23*1em;
    letter-spacing: 0.05em;
    padding: 5px 0 7px;
    display: flex;
    justify-content: center;
  }

  &__content {
    padding: 39px var(--grid-spacer) 30px;
  }


  &__field {
    position: relative;

    input[type=text], textarea {
      background: white;
      border: 1px solid #C7C7C7;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
    }

    textarea {
      height: 174px;
    }
  }

  &__button-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  &__button {
    width: 200px;
    color: var(--dark-shades);
  }

  &__success {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    padding: 0 var(--grid-spacer);
    transition: {
      property: opacity;
      duration: var(--default-transition-timing);
    };
  }

  &__success-icon-wrap {
    height: 100px;
    width: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__success-icon {
    position: absolute;
    left: calc(50% - 35px);
    top: calc(50% - 35px);
    width: 70px;
    height: 70px;
    z-index: 30;
    border-radius: 100px;
    background-color: var(--t);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: {
      property: background-color;
      duration: var(--default-transition-timing);
      delay: .6s;
    };

    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      opacity: 0;
      transition: {
        property: opacity;
        duration: var(--default-transition-timing);
        delay: .7s;
      };
    }
  }

  &__success-circle {
    position: relative;
    z-index: 20;
    background-color: var(--primary-16);
    border-radius: 100px;
    height: 100px;
    width: 100px;
    transform: scale(.2);
    opacity: 0;
    transition: {
      property: opacity, transform;
      duration: var(--default-transition-timing);
      delay: .4s;
    };
  }

  &__success-title {
    color: var(--color-bg);
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 5px;
    transform: translateY(15px);
    opacity: 0;
    text-align: center;
    transition: {
      property: opacity, transform;
      duration: var(--default-transition-timing);
      delay: .6s;
    };
  }

  &__success-subtitle {
    color: var(--color-gray-text);
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    transform: translateY(25px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      duration: var(--default-transition-timing);
      delay: .7s;
    };
  }
}

@include respond-up('large') {
  .capper-review-form {

  }
}

@include respond-up('medium') {
  .capper-review-form {

  }
}

@include respond('medium') {
  .capper-review-form {

  }
}

@include respond-down('medium') {
  .capper-review-form {

  }
}

@include respond-down('small') {
  .capper-review-form {

  }
}