:root {
  --default-input-height: 48px;
  --errors-color: var(--alert-color, #b50000)
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: var(--errors-color);
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: 1px solid #c1c1c1;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  .errors {
    position: absolute;
    top: 100%;
  }

  .review-type {
    display: flex;
    flex-direction: column;
    align-items: center;

    .radio-list {
      display: flex;
      background-color: white;
      border-radius: 6px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      margin-top: 4px;
      border: 1px solid var(--gray-light);

      &__icon {
        svg path {
          &[fill] {
            fill: var(--current-color);
          };
          &[stroke] {
            stroke: var(--current-color);
          }
        }
      }

      &__title {
        display: none;
      }

      input {
        display: none;
        &:checked {
          + label {
            background-color: var(--primary-10);
          }
        }
      }

      label {
        height: 100%;
        color: var(--primary-color);
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding: 7px 12px 3px;
        margin: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: {
          property: background-color;
          duration: var(--default-transition-timing);
        };
      }

      li {
        &:nth-child(n + 2) {
          border-left: 1px solid var(--gray-light);
        }

        &:nth-child(1) {
          --current-color: var(--primary-color);
        }
        &:nth-child(2) {
          --current-color: var(--color-gray-text);
        }
        &:nth-child(3) {
          --current-color: var(--color-red);
        }
      }
    }
  }
}