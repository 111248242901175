.review-item {
  background-color: white;
  border-radius: 6px;

  &__name {
    font-style: italic;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: var(--dark-shades);
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--dark-shades);

    a {
      color: var(--primary-color);
    }
  }

  &__actions-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
  }

  &__button {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: var(--primary-color);
  }

  &__bottom {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--gray-light);
    justify-content: space-between;
  }

  &__diff {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &._green {
      --diff-color: var(--primary-color);
    }

    &._red {
      --diff-color: var(--color-red);
      .review-item__diff-icon {
        transform: rotate(180deg);
      }
    }
    &._gray {
      --diff-color: var(--color-gray-text);
    }
  }

  &__icon {
    grid-column: 1/2;
    display: flex;

    &._type {
      &_10 {
        --icon-color: var(--primary-color);
      }
      &_20 {
        --icon-color: var(--color-gray-text);
      }
      &_30 {
        --icon-color: var(--color-red);
      }

      svg path {
        &[stroke] {
          stroke: var(--icon-color);
        }
        &[fill] {
          fill: var(--icon-color);
        }
      }
    }


  }

  &__diff-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    margin-right: 6px;
    transition: {
      property: transform;
      duration: var(--default-transition-timing);
    };

    svg {
      width: 31px;
      height: 31px;

      path[stroke] {
        stroke: var(--diff-color);
      }

      path[fill] {
        fill: var(--diff-color);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__actions-buttons {
    display: flex;
    align-items: center;
  }

  &__content {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__action {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-left: 7px;

    svg path {
      transition: {
        property: fill, stroke;
        duration: var(--default-transition-timing);
      };
    }

    &:first-child {
      &._active {
        svg {
          path[fill] {
            fill: var(--primary-color);
          }
          path[stroke] {
            stroke: var(--primary-color);
          }
        }
      }
    }

    &:nth-child(2) {
      transform: rotate(180deg);

      &._active {
        svg {
          path[fill] {
            fill: var(--color-red);
          }
          path[stroke] {
            stroke: var(--color-red);
          }
        }
      }
    }

    svg {
      path[fill] {
        fill: var(--color-gray-text);
      }
      path[stroke] {
        stroke: var(--color-gray-text);
      }
    }
  }

  &__date {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-gray-text);
  }

  &__diff-number {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: var(--diff-color);;
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@include respond-up('large') {
  .review-item {

    &__name {
      grid-column: 1/5;
    }

    &__diff {
      grid-column: 5/7;
    }

    &__icon {
      justify-content: flex-end;
      align-items: flex-start;
    }

    &__content {
      padding: 22px 22px 11px;
      grid-template-columns: repeat(6, 1fr);
    }

    &__bottom {
      padding: 11px 22px;
    }

    &__text {
      grid-column: 2/7;
    }

    &__date {
      grid-column: 1/7;
    }
  }
}

@include respond-down('medium') {
  .review-item {

    &__name, &__text, &__icon, &__date {
      grid-column: 1/3;
    }

    &__name {
      grid-row: 2/3;
      grid-column: 1/3;
    }

    &__content {
      padding: 15px 15px 11px;
      grid-template-columns: repeat(2, 1fr);
    }

    &__icon {
      grid-row: 1/2;
    }

    &__diff {
      grid-row: 1/2;
      grid-column: 2/3;
    }

    &__bottom {
      padding: 11px 15px;
    }

    &__actions-text {
      font-size: 14px;
    }
  }
}
