.footer {
  background-color: white;
  box-shadow: 0px -1px 30px rgba(0, 0, 0, 0.1);

  &__top {

  }

  &__main {
    display: flex;
    justify-content: space-between;
  }

  &__copyright {
    display: flex;
    align-items: center;
    color: var(--dark-shades);
    font-weight: 400;
    font-size: 12px;
    line-height: 156.78%;
  }

  &__contact {
    display: flex;
  }

  &__contact-label {
    font-weight: 500;
    font-size: 15px;
    line-height: 18/15*1em;
    color: var(--dark-shades);
    margin-right: 5px;
  }

  &__contact-link {
    display: flex;
    align-items: center;
  }

  &__contact-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    svg {
      margin-left: -3px;
    }
  }

  &__contact-name {
    font-weight: 500;
    font-size: 15px;
    line-height: 18/15*1em;
    color: var(--color-telegram);
  }

  &__links {
    display: flex;
    align-items: center;
  }


  &__link {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: var(--primary-color);
    letter-spacing: 0;
  }

  &__info {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-gray-text);
    letter-spacing: 0;
    align-items: center;
  }
}

@include respond-up('large') {
  .footer {

    &__top {
      padding: 23px 0;
      border-bottom: 1px solid var(--gray-light);
    }

    &__bottom {
      padding: 25px 0;
    }

    &__copyright {
      grid-column: 11/13;
    }

    &__contact {
      align-items: center;
    }

    &__info {
      grid-column: 6/11;
    }

    &__links {
      grid-column: 1/6;
    }
    &__link {
      &:nth-child(n + 2) {
        position: relative;
        margin-left: 10px;
        padding-left: 10px;

        &::before {
          content: '';
          position: absolute;
          width: 1px;
          height: 22px;
          left: 0;
          top: calc(50% - 10px);
          background-color: var(--gray-light);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .footer {

  }
}

@include respond('medium') {
  .footer {

  }
}

@include respond-down('medium') {
  .footer {
    &__top {
      padding-top: 33px;
    }

    &__main {
      flex-direction: column;
    }
    &__bottom {
      padding: 15px 0 17px;
    }

    &__links {
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid var(--gray-light);
      padding-bottom: 18px;
      grid-column: 1/5;
    }


    &__link {
      margin-bottom: 10px;
    }

    &__copyright,
    &__contact,
    &__info {
      grid-column: 1/5;
    }

    &__copyright {
      text-align: center;
      justify-content: center;
    }

    &__info {
      text-align: center;
    }

    &__contact {
      margin-top: 11px;
      flex-direction: column;
    }

    &__contact-label {
      margin-bottom: 2px;
    }
    &__menu-block {
      grid-column: 1/5;
      padding-top: 35px;
    }
  }
}

@include respond-down('small') {
  .footer {

  }
}