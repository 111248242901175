.capper-page {
  &__header {

  }

  &__content {
    display: grid;
  }

  &__main-content {

  }

  &__reviews-stat {
    margin-bottom: var(--grid-gap);
  }

  &__reviews {

  }

  &__side-content {

  }

  &__capper-info {
    margin-bottom: var(--grid-gap);
  }

  &__sticky-wrap {

  }

  &__sticky-content {
  }
}

@include respond-up('large') {
  .capper-page {
    &__header {
      margin-bottom: 40px;
    }

    &__content {
      grid-template-columns: repeat(12, 1fr);
      grid-gap: var(--grid-gap);
    }

    &__main-content {
      grid-column: 1/9;
    }

    &__side-content {
      grid-column: 9/13;
      display: flex;
      flex-direction: column;
    }

    &__sticky-wrap {
      flex: 1 1 auto;
    }

    &__sticky-content {
      position: sticky;
      top: calc(var(--header-height) + var(--grid-gap));
    }

    &__side-form {
      &_mobile {
        display: none;
      }
    }
  }
}

@include respond-down('medium') {
  .capper-page {
    &__header {
      margin-bottom: 40px;
    }

    &__main-content {
      grid-row: 2/3;
    }

    &__side-content {
      grid-row: 1/2;
    }

    &__side-form {
      margin-bottom: var(--grid-gap);

      &_desktop {
        display: none;
      }
    }

    &__reviews {
      padding-top: 30px;
    }
  }
}
