.capper-header {
  position: relative;

  &._has-image {

  }

  &._without-image {

  }

  &._has-link {

  }

  &._without-link {

  }

  &._green {
    .capper-header {
      &__name {
        background: var(--primary-gradient);
      }

      &__link-icon_20 svg {
        [fill] {
          fill: var(--primary-color);
        }
        [stroke] {
          stroke: var(--primary-color);
        }
      }

      &__link-text, &__link {
        color: var(--primary-color);
      }
    }
  }

  &._red {
    .capper-header {
      &__name {
        background: var(--red-gradient);
      }
      &__link-text, &__link {
        color: var(--color-red);
      }
      &__link-icon_20 svg {
        [fill] {
          fill: var(--color-red);
        }
        [stroke] {
          stroke: var(--color-red);
        }
      }
    }
  }

  &._gray {
    .capper-header {
      &__name {
        background: var(--color-gray-text);
      }
      &__link-text, &__link {
        color: var(--color-gray-text);
      }
      &__link-icon_20 svg {
        [fill] {
          fill: var(--color-gray-text);
        }
        [stroke] {
          stroke: var(--color-gray-text);
        }
      }
    }
  }

  &__image-wrap {
    position: absolute;
    z-index: 20;
    border-radius: 100px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    overflow: hidden;
  }

  &__picture {
    display: block;
    height: 100%;
    width: 100%;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__content {
    position: relative;
    z-index: 10;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  }

  &__name {
    display: flex;
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.05em;
    color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 18;
  }

  &__link-block {
    background-color: white;
    display: flex;
    position: relative;
    z-index: 15;
  }

  &__link-type {
    display: flex;
    align-items: center;
  }

  &__link-text {
    font-size: 14px;
    line-height: 17/14*1em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 800;
    font-style: italic;
  }

  &__link {
    font-weight: 700;
    line-height: 18/15*1em;
    letter-spacing: 0.05em;
  }

}

@include respond-up('large') {
  .capper-header {
    &._has-image {
      .capper-header__name, .capper-header__link-block {
        padding-left: 180px;
      }
    }

    &._without-image {
      .capper-header__name, .capper-header__link-block {
        padding-left: 25px;
      }
    }

    &__image-wrap {
      position: absolute;
      width: 108px;
      height: 108px;
      left: 40px;
      top: -6px;
      border: 8px solid white;
    }

    &__content {
      border-radius: 6px;
      overflow: hidden;
    }

    &__name {
      font-size: 40px;
      line-height: 49/40*1em;
      height: 58px;
      align-items: center;
    }

    &__link-block {
      height: 39px;
      align-items: stretch;
    }

    &__link-type {
      padding-right: 41px;
      margin-right: 20px;
      border-right: 1px solid var(--gray-light);
      align-items: stretch;
    }

    &__link-icon {
      margin-right: 9px;
    }

    &__link-text, &__link-icon, &__link-text, &__link {
      display: flex;
      align-items: center;
    }
  }
}

@include respond-down('medium') {
  .capper-header {
    margin-left: calc(var(--grid-spacer) * -1);
    margin-right: calc(var(--grid-spacer) * -1);

    &._has-image {
      .capper-header {
        &__name {
          padding: 0 var(--grid-spacer) 0 110px;
        }
      }
    }

    &._without-image {
      .capper-header {
        &__name {
          padding: 0 var(--grid-spacer);
        }
      }
    }

    &__image-wrap {
      position: absolute;
      width: 76px;
      height: 76px;
      left: 20px;
      top: 9px;
      border: 4px solid white;
    }

    &__name {
      min-height: 94px;
      font-size: 28px;
      line-height: 34/28*1em;
      align-items: center;
    }

    &__link-block {
      flex-direction: column;
      align-items: center;
      padding: 15px var(--grid-spacer) 15px;
    }

    &__link-type {
      margin-bottom: 10px;
    }

  }
}
