.capper-reviews-stat {
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  &__header {
    background-color: var(--primary-color);
    color: white;
    font-weight: 700;
    font-size: 23px;
    line-height: 28/23*1em;
    letter-spacing: 0.05em;
    padding: 5px 0 7px;
    display: flex;
    justify-content: center;
  }

  &__content {
  }

  &__block {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__name {
    font-weight: 700;
    font-size: 15px;
    line-height: 18/15*1em;
    letter-spacing: 0.05em;
    color: black;
  }

  &__value {
    display: flex;
    justify-content: center;
    align-items: center;

    &._green {
      --current-color: var(--primary-color);
    }

    &._red {
      --current-color: var(--color-red);
    }

    &._gray {
      --current-color: var(--color-gray-text);
    }
  }

  &__value-icon {
    flex: 0 0 40px;
    height: 40px;
    width: 40px;
    margin-right: 15px;
    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: var(--current-color);
      }
    }
  }

  &__value-number {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: var(--current-color);
  }

  &__diff {
    border-top: 1px solid var(--gray-light);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__diff-text {
    font-weight: 700;
    font-size: 15px;
    line-height: 18/15*1em;
    letter-spacing: 0.05em;
    color: var(--dark-shades);
  }

  &__diff-value {
    font-weight: 700;
    font-size: 29px;
    line-height: 35/29*1em;
    letter-spacing: 0.05em;

    &._green {
      color: var(--primary-color);
    }

    &._gray {
      color: var(--color-gray-text);
    }

    &._red {
      color: var(--color-red);
    }
  }
}

@include respond-up('large') {
  .capper-reviews-stat {

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__block {
      padding: 23px 0 35px;

      &:nth-child(n+2) {
        border-left: 1px solid var(--gray-light);
      }
    }

    &__diff {
      padding: 7px 29px 10px;
    }

    &__diff-text {
      margin-right: 20px;
    }

    &__name {
      margin-bottom: 31px;
    }

    &__link {
      display: none;
    }
  }
}


@include respond-down('medium') {
  .capper-reviews-stat {


    &__content {
      display: flex;
      flex-direction: column;
    }

    &__block {
      padding: 10px 0 11px;

      &:nth-child(n + 2) {
        border-top: 1px solid var(--gray-light);
      }
    }

    &__name {
      margin-bottom: 6px;
    }

    &__diff {
      padding: 19px 13px 14px;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-10);
      padding: 8px 0 10px;
    }

    &__link-icon {
      display: flex;
      align-items: center;
      margin-left: 3px;
    }

    &__link-text {
      color: var(--primary-color);
      font-weight: 400;
      font-size: 15px;
      line-height: 18/15*1em;
      letter-spacing: 0.05em;
    }

    &__diff-text {
      margin-right: 6px;
    }
  }
}
