.layout {
  display: flex;
  justify-content: space-between;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;

  &__main {
    flex: 1 1 auto;
    position: relative;
    z-index: 20;
  }

  &__wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    z-index: 20;
  }

  &__header {
    z-index: 30;
    position: relative;
  }

  &__footer {
    flex: 0 0 auto;
  }

  &__content{

  }

  &__with-sidebar-block {

  }

  &__before-main-content {
  }

  &__main-content {
  }

  &__sidebar-content {
  }

  &__sidebar-sticky-block {

  }
}

@include respond-up('large') {
  .layout {
    --sidebar-height: calc(var(--vh, 1vh) * 100 - var(--header-height));

    &__content {
      padding-bottom: 60px;
    }

    &__before-main-content {
      grid-column: 1/10;
    }

    .breadcrumbs {
      grid-column: 1/10;
      margin-bottom: var(--grid-gap);
    }

    &__main-content {
      grid-column: 1/10;
    }

    &__sidebar-content {
      grid-column: 10/13;
      grid-row: 1/3;
    }

    &__sidebar-sticky-block {
      position: sticky;
      top: var(--header-height);
      max-height: var(--sidebar-height);
      overflow: hidden;
    }

    &__sidebar-sticky-inner {
      padding-top: var(--grid-spacer);
      overflow: auto;
      max-height: calc(var(--sidebar-height) + 30px);
      padding-bottom: 80px;
      width: calc(100% + 30px);
      padding-right: 30px;
    }

    &__search {
      margin-bottom: var(--grid-gap);
    }
  }
}

@include respond-down('medium') {
  .layout {
    &__content {
      padding-bottom: 80px;
    }

    &__with-sidebar-block {
      padding: var(--grid-spacer) 0;
    }


    &__before-main-content,
    &__main-content,
    &__sidebar-content {
      grid-column: 1/5;
    }

    &__before-main-content {
      grid-row: 1/2;
    }

    &__sidebar-content {
      grid-row: 2/3;
    }

    &__main-content {
      grid-row: 3/4;
    }

    &__sidebar-sticky-block {
      .capper-searcher {
        display: none;
      }
    }
  }
}