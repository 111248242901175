.capper-info {
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  &__header {
    background-color: var(--primary-color);
    color: white;
    font-weight: 700;
    font-size: 23px;
    line-height: 28/23*1em;
    letter-spacing: 0.05em;
    padding: 5px 0 7px;
    display: flex;
    justify-content: center;
  }

  &__list {

  }

  &__item {
    display: flex;
    align-items: stretch;
    min-height: 56px;

    &:nth-child(n + 2) {
      border-top: 1px solid var(--gray-light);
    }
  }

  &__name {
    font-weight: 700;
    font-size: 15px;
    line-height: 18/15*1em;
    letter-spacing: 0.05em;
    flex: 0 0 161px;
    color: var(--primary-color);
    padding: 0 18px;
    border-right: 1px solid var(--gray-light);
    display: flex;
    align-items: center;
  }

  &__value {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 0 18px;

    &_column {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__value-icon {
    flex: 0 0 34px;
    width: 34px;
    display: flex;
    align-items: center;
    margin-right: 15px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__value-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19/16*1em;
    letter-spacing: 0.05em;
    color: var(--primary-color);
    display: flex;
    align-items: center;

    &_bold {
      font-weight: 700;
    }
  }
}

@include respond-up('large') {
  .capper-info {
    border-radius: 6px;
    overflow: hidden;
  }
}

@include respond-down('medium') {
  .capper-info {
    margin-right: calc(var(--grid-spacer) * -1);
    margin-left: calc(var(--grid-spacer) * -1);
  }
}
