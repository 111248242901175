/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  medium: (
    from: 768,
    to: 1279
  ),
  large: (
    from: 1280
  )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Effra", "Arial", sans-serif;
  --h-font: "Montserrat", "Arial", sans-serif;
  --letter-spacing: .05em;

  --t: transparent;

  --primary-color: #56BE6A;
  --alert-color: #b50000;
  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-timing: .4s;
  --default-transition-function: ease-in-out;
  --primary-color-opacity: rgba(1, 199, 103, .85);

  --color-bg: #EBF0EA;
  --color-bg-light: #122023;
  --color-yellow: #FFBC0E;

  --primary-16: rgba(98, 132, 255, 0.10);
  --primary-10: rgba(86, 190, 106, 0.1);
  --primary-16-on-bg: #101C2C;
  --primary-16-on-white: #DDF1FF;
  --primary-8: rgba(14, 157, 255, 0.08);

  --color-red: #C51D1D;
  --color-red-16: rgba(197, 29, 29, 0.16);
  --color-red-6: rgba(197, 29, 29, 0.06);
  --color-red-6-on-white: #FBF1F1;
  --red-gradient: linear-gradient(90deg, #C51D1D 0%, #FF6E4F 100%);

  --color-green-8: rgba(50, 169, 72, 0.08);
  --color-green-8-on-white: #EBF7ED;

  --color-gray-text: #9A9A9A;
  --color-gray-bg: #EDF3F6;

  --color-telegram: #29B6F6;

  --white-60: rgba(255, 255, 255, 0.6);

  --primary-gradient: linear-gradient(97.35deg, #56BE6A 0.95%, #077C5D 97.81%);
  --primary-gradient-reverse: linear-gradient(90deg, #077C5D 0%, #56BE6A 100%);

  --gradient-green: linear-gradient(97.35deg, #56BE6A 0.95%, #077C5D 97.81%);

  --dark-shades: #1E1F22;

  --gray-light: #DEDEDE;

  //--light-shades: #E8E8D8;
  //--light-accent: #80B899;
  //--main-brand: #56BE6A;
  //--dark-accent: #077C5D;

  //--main-10: #32A948;
  //--main-light: #EBF6ED;
  //
  //--white-5: #FFFFFF;
  //--gray-bg: #EDF3F6;
  //--green-20: #A3FFB4;
}
