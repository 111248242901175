.capper-reviews {
  &__list {
    padding-top: var(--review-padding);
  }

  &__title {
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.05em;
    color: var(--dark-shades);
  }

  &__filter {

  }

}

@include respond-up('large') {
  .capper-reviews {
    &__title {
      margin-bottom: 39px;
    }

    &__filter {
      margin-bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .capper-reviews {
    --review-padding: 34px;
    --line-shift: 23px;
    --line-width: 20px;

    &__answer {
      padding-left: 41px;
    }

    &__title {
      margin-bottom: 26px;
    }

    &__filter {
      margin-bottom: 15px;
    }
  }
}