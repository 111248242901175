.review-list {

  &__item {
    &:nth-child(n + 2) {
      margin-top: var(--review-padding);
    }
  }

  &__answer {
    padding-top: var(--review-padding);

    &:not(:last-child) {
      .review-list__review-answer::after {
        content: '';
        position: absolute;
        width: 1px;
        background-color: var(--gray-light);
        left: calc(var(--line-width) * -1);
        height: calc(100% + var(--review-padding));
        bottom: calc((var(--review-padding) + var(--line-shift)) * -1);
      }
    }

    &:first-child {
      .review-list__review-answer::after {
        height: calc(100% + var(--review-padding) * 2 + var(--line-shift));
      }

      &:last-child {
        .review-list__review-answer::after {
          content: '';
          position: absolute;
          width: 1px;
          background-color: var(--gray-light);
          left: calc(var(--line-width) * -1);
          height: calc(var(--review-padding) + var(--line-shift));
          top: calc(-1 * var(--review-padding))
        }
      }
    }
  }

  &__review-answer {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: var(--gray-light);
      width: var(--line-width);
      left: calc(var(--line-width) * -1);
      top: var(--line-shift);
    }
  }

  &__empty {
    color: var(--color-gray-text);
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
}

@include respond-up('large') {
  .review-list {
    --review-padding: 39px;
    --line-shift: 31px;
    --line-width: 102px;

    &__item, &__answer {
      display: grid;
      grid-gap: 0 var(--grid-gap);
      grid-template-columns: repeat(8, 1fr);
    }

    &__review {
      grid-column: 1/7;
    }

    &__review-answer {
      grid-column: 3/9;
    }

    &__answers {
      grid-column: 1/9;
    }
  }
}

@include respond-down('medium') {
  .review-list {
    --review-padding: 34px;
    --line-shift: 23px;
    --line-width: 20px;

    &__answer {
      padding-left: 41px;
    }
  }
}
